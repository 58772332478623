body {
  background:#f3f3f3;
}

img {
  width: 100%;
  height: 220px;
}

a {
  color: #e74c3c !important;
}

button:focus {
  outline: none;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #2c3e50;
  height: 90px;
  padding: 30px;
  color: white;
  margin-bottom: 2rem;
}

.App-title {
  font-size: 1.5em;
}

.recipe_buttons {
  border: solid 2px #e74c3c;
  background: transparent;
  padding: 0.4rem 0.6rem;
  margin: 0 0.2rem;
  border-radius: 1px;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 0.7rem;
}

.recipes__box {
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 5px;
  box-shadow: -1px 2px 34px -13px rgba(0,0,0,0.75);
  text-align: left;
  overflow: hidden;
}

.recipe__text {
  margin-left: 15px;
}

.recipes__title {
  color: #e74c3c;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 1.8rem 0 0.2rem 0;
}

.recipes__subtitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 2px;
}

.recipes__subtitle span {
  text-transform: none;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.form__input {
  background: transparent;
  border: 0;
  border-bottom: solid 2px #2c3e50;
  margin-right: 10px;
  letter-spacing: 2px;
  width: 10%;
  padding: 0.4rem;
}

.form__button {
  border: solid 2px #e74c3c;
  background: transparent;
  padding: 0.2rem 0.9rem;
  margin: 0 0.2rem;
  border-radius: 1px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 0.7rem;
}

input[type="text"] { 
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f3f3f3 inset;
  -webkit-text-fill-color: #2c3e50 !important;
}

/******** RECIPE.js ********/

.active-recipe {
  margin: 0 auto;
  width: 60%;
}

.active-recipe__img {
  height: 500px !important;
  margin-top: 3rem;
}

.active-recipe__title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 1rem 0;
}

.active-recipe__publisher, .active-recipe__website {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.active-recipe__publisher span {
  text-transform: none;
  font-weight: 300;
  letter-spacing: 2px;
  margin-left: 5px;
}

.active-recipe__website span {
  text-transform: lowercase;
  font-weight: 300;
  letter-spacing: 2px;
  color: #e74c3c;
  margin-left: 10px;
}

.active-recipe__button {
  border: solid 2px #e74c3c;
  background: transparent;
  padding: 0.2rem 0.9rem;
  margin: 0 0.2rem;
  border-radius: 1px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
}